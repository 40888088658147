@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;800;900&display=swap);
body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.swiper-button-next{
  color: #ec3237 !important;
}
.swiper-button-prev{
  color: #ec3237 !important;
}


.container{
  margin:0;
  padding:0;
  text-decoration:none;
  font-family: "open_sansregular", sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/*Tabla*/

.contenedor-precios{
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top:1em;
}

.contenedor-precios h3{
  font-size: 26px;
  margin-bottom:.5em;
}

.contenedor-tabla{
  display:flex;
  justify-content: space-around;
  flex-direction: column;
  width:100%;
}

.tabla-precios{
  width:100%;
  border-collapse: collapse;
}

.tabla-precios:last-of-type{
  margin-top: -2px;
}

.fila-precios td{
  border-top:2px solid #474747;
  border-bottom:2px solid #474747;
  padding: .5em;
}

.fila-precios td:first-of-type{
  width:30px;
}
/*Tabla - Fin*/

@media screen and (min-width:600px){
  .contenedor-tabla{
      width:60%;
  }
}

@media screen and (min-width:768px){
  /*Tabla*/
  .contenedor-tabla{
      width:40%;
  }
  /*Tabla - Fin*/
}

@media screen and (min-width:900px){
  /*Tabla*/
  .contenedor-precios h3{
      font-size: 2.5em;
  }

  .contenedor-tabla{
      flex-direction: row;
      width:90%;
  }

  .tabla-precios{
      width:50%;
      margin-left:2vh;
      margin-right: 2vh;
  }

  .tabla-precios:last-of-type{
      margin-top: 0;
  }

  .fila-precios td:first-of-type{
      width:200px;
  }
  /*Tabla - Fin*/
}
@media screen and (min-width:1200px){
  /*Tabla*/
  .contenedor-tabla{
    padding:1.2em;
      width:70%;
  }
  /*Tabla - Fin*/
}

/* CSS - Cafe Desktop*/
.cafe-contenedor{
  display:flex;
  width: 100%;
  margin: 0;
}

.cafe-contenido{
  display:flex;
  width:100%;
  background-color: #ec3237;
  color:#fff;
  border-radius: 1.5em;
}
.cafe-contenido > *{
  margin-right: 1em;
}

.cafe-contenido ul{
  list-style: none;
}

.cafe-contenido ul li{
  margin-bottom:1em;
}

.lista-cafe li:nth-child(even){
  font-weight: bold;
}

.lista-otros li:nth-child(odd){
  font-weight: bold;
}

.cafe-imagen{
  background-size: cover;
  width:40vw;
  border-top-right-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
}

/*Cafe menu mobile*/


.cafe-contenedor-mobile{
  margin:1em auto;
  width: 90%;
}

.cafe-contenido-mobile{
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: #ec3237;
  color:#fff;
  border-radius: 1.5em;
  padding:2em;
  margin:auto;
  text-align: center;
}

.cafe-contenido-mobile ul{
  list-style: none;
}

.cafe-contenido-mobile ul li{
  margin-bottom:1em;
}

.lista-cafe-mobile{
  padding-left: 0;
  margin: 0;
}

.lista-cafe-mobile li:nth-child(even){
  font-weight: bold;
}

.cafe-icono-mobile{
  width:64px;
  height: 64px;
  background-repeat: no-repeat;
  margin-bottom:1em;
}

@media screen and (min-width:600px){
  .cafe-contenido-mobile{
      width:50%;
  }
}

/*******BARRA MENU MOBILE******/
.menu-contenedor{
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #ec3237;
  font-weight: 900;
  text-align: right;
  margin-left:auto;
}

.menu{
  margin-top:6em;
}

.menu-contenedor ul{
  list-style: none;
}

.menu-contenedor li{
  margin-bottom:.5em;
}

.menu-contenedor a{
  color:#fff;
}

.menu-contenedor a:hover{
  color:#8f1f23;
}

.menu-contenedor .menu a{
  font-size: 1.5em;
}

.menu-contenedor .redes{
  justify-content: right;
  display:flex;
  margin-top:2em;
}

.menu-contenedor .redes li{
  margin-right: .5em;
}

.menu-contenedor .redes li:first-of-type a,
.menu-contenedor .redes li:last-of-type a{
  display:block;
  text-indent:-1000px;
  width:40px;
  height:40px;
  font-size:0;
  overflow: hidden;
}

.cerrar-boton{
  cursor: pointer;
}
